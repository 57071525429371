import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Wild_ _ness/Weird_ness`}</h1>
    <h4>{`August 6–12, 2016 | Bamfield, British Columbia`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "550px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQT/xAAXAQEBAQEAAAAAAAAAAAAAAAADAgAB/9oADAMBAAIQAxAAAAHWeSw2ybtekFNhFf/EABoQAAIDAQEAAAAAAAAAAAAAAAACAQMREhP/2gAIAQEAAQUC8zkwi1h3slalvRJnBVjD/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESEP/aAAgBAwEBPwGSBZ//xAAZEQABBQAAAAAAAAAAAAAAAAAAAQIQEhP/2gAIAQIBAT8BuaIOj//EABsQAAICAwEAAAAAAAAAAAAAAAABEBESIjGB/9oACAEBAAY/Ap4apIrFP0Rcf//EABoQAAMBAQEBAAAAAAAAAAAAAAABETEhQVH/2gAIAQEAAT8hocitYMajGJPPtJalaW8fRi3WLD//2gAMAwEAAgADAAAAEP8AB0L/xAAWEQEBAQAAAAAAAAAAAAAAAAABABD/2gAIAQMBAT8QEytf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQIf/aAAgBAgEBPxBBFLuf/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIVFxMWGR/9oACAEBAAE/EH0QytXRHFwppxHsT5G2HDyHXE3IgKLLlWdXRzioAtTdZem11P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OTHER/WISE",
          "title": "OTHER/WISE",
          "src": "/static/581f2660ea18680dda49c167a7128284/d7854/IMG_4081-550x550.jpg",
          "srcSet": ["/static/581f2660ea18680dda49c167a7128284/f93b5/IMG_4081-550x550.jpg 300w", "/static/581f2660ea18680dda49c167a7128284/d7854/IMG_4081-550x550.jpg 550w"],
          "sizes": "(max-width: 550px) 100vw, 550px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://archive.designinquiry.net/contributions/wild_-_-ness-weird-_-ness/" title="WILD_ _ NESS // WEIRD _ NESS by Gwen MacGregor, Lewis Nicholson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/increasingly-i-have-become-suspicious-of-the-wilderness/" title="Increasingly I have become suspicious of the wilderness by Gwen MacGregor" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/the-crabs-were-elusive-little-george-retired-and-the-weirdness-of-the-crustacea-transcends-time-and-space/" title="The Crabs were elusive, Little George Retired, and the Weirdness of the Crustacea Transcends Time and Space” by Rodney Sayers" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/anthro-topo-scene/" title="ANTHRO-TOPO-Scene by Hester Jiskoot" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/wildnessweirdness/" title="Wildness/Weirdness by Dagmar Dahle" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/collaboration-mediated-by-nature/" title="Collaboration mediated by nature by Cat Normoyle, Rebecca Tegtmeyer" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`Wild_ _ness/Weird_ness explores pathless, tangled places that quicken the eye and body to wholly alien and complex languages.`}</strong></p>
    <p><strong parentName="p">{`Explorations of the natural world often focus on an element of environmental guilt or awestruck wonder. Being (rather than talking about) in pathless, tangled places quicken the eye and body to wholly different and complex languages. As the relationship between the manufactured and natural worlds reaches a crisis point, how might humans unlearn from pre- and post-industrial approaches to problem-solving?`}</strong></p>
    <p><strong parentName="p">{`If wild_ `}<em parentName="strong">{`ness situates itself apart from the cultivated world—untamed, unrestrained, unruly—and weird_ness draws its roots from a supernatural state, what do we need to do to suspend belief? Is there something to being in wild`}</em>{` _ness that offers an alternative to the design process? Can design bewilder?`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      